.instructions p {
    margin-bottom: 8px;
    line-height: 24px;
}

#root .new-room .instructions h3.how-it-works {
    margin-top: 32px;
}

@media only screen and (max-width: 600px) {
    .instructions {
        margin: 12px 0;
    }

    .instructions p {
        font-size: 12px;
    }
}