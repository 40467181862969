body:has(.new-guest) {
    background: #006064 linear-gradient(209.9deg,#006064 24.42%,#003537 115.13%);
}

.new-guest {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#root .new-guest h1, #root .new-guest h2 {
    color: white;
}

#root .new-guest h1 {
    text-transform: uppercase;
    font-size: 44px;
    line-height: 44px;
    font-weight: 800;
    padding: 0 0 20px;
}

#root .new-guest h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 0 64px;
}

#root .new-guest .MuiOutlinedInput-root {
    background-color: white;
}