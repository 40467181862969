.actions {
    margin-right: 24px;
    display: flex;
    flex-direction: column;
}

.actions>div {
    margin-bottom: 8px;
}

.action-button {
    width: 100%;
}

.actions button.icon {
    color: #00838f;
}

.actions button.icon.viewer {
    color: red;
}

.modal .MuiSelect-select {
    min-width: 500px;
}

@media only screen and (max-width: 600px) {
    .actions {
        margin-right: 0;
        flex-direction: row;
        justify-content: center;
        margin: 12px 0;
    }

    .modal .MuiBox-root {
        width: calc(100vw - 100px);
    }

    .modal .MuiBox-root .MuiFormControl-root {
        width: unset;
    }

    .MuiPopover-root .MuiPaper-root {
        min-width: unset !important;
        max-width: calc(100vw - 32px);
    }

    .modal .MuiSelect-select {
        min-width: unset;
        overflow-wrap: break-word;
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .MuiPopover-root .MuiPaper-root .MuiList-root {
        padding-inline-start: 0px;
    }
}