body:has(.new-room) {
    background: #006064 linear-gradient(209.9deg,#006064 24.42%,#003537 115.13%);
}

#root .new-room h1, #root .new-room h2, #root .new-room h3, #root .new-room p {
    color: white;
}

#root .new-room a {
    color: white;
}

#root .new-room .MuiOutlinedInput-root {
    background-color: white;
}

#root .new-room h1 {
    text-transform: uppercase;
    font-size: 44px;
    line-height: 44px;
    font-weight: 800;
    padding: 0 0 20px;
}

#root .new-room h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 0 64px;
}

#root .new-room h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
}

.new-room {
    display: flex;
    padding: 32px 0;
    align-items: center;
    max-width: 54.75em;
    width: 54.75em;
}

.new-room .left {
    width: 60%;
    max-width: 60%;
}

.new-room .right {
    width: 40%;
    max-width: 40%;
}

.new-room .counter {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin-top: 64px;
}

.new-room .counter .counter-text {
    display: flex;
    align-items: center;
}

.new-room .counter .count {
    font-weight: 700;
    font-size: 36px;
    margin: 0 4px;
}

@media only screen and (max-width: 600px) {
    .new-room {
        flex-direction: column;
        padding: 24px;
    }

    #root .new-room h1 {
        font-size: 24px;
        line-height: 24px;
        padding: 0 0 8px;
    }

    #root .new-room h2 {
        font-size: 16px;
        margin: 0 0 24px;
    }

    .new-room .counter {
        margin-bottom: 12px;
    }

    .new-room .left {
        width: 100%;
        max-width: 100%;
    }
    
    .new-room .right {
        width: 100%;
        max-width: 100%;
    }

    .new-room .counter {
        margin-top: 12px;
    }

    .new-room .counter p {
        font-size: 12px;
    }
}