header {
  display: flex;
  justify-content: center; 
  width: 100%; 
  box-shadow: 0 0.125em 0.5em 0 rgba(0,0,0,.1); 
  background: white;
}

header .header-container {
  max-width: 54.75em;
  width: 54.75em;
  display: flex;
  justify-content: space-between;
}

header .header-container .app-logo {
  max-width: 150px;
}

header .header-container .menu ul {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
}

header .header-container .menu ul li {
  list-style: none;
  margin: 0 16px;
}

body {
  margin: 0;
  font-family: Roboto, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #32485dc4;
  display: flex;
  max-width: 100vw;
  align-items: center;
  min-height: 100vh;
  background: #eee;
}

.content {
  flex: 1 1 auto;
  display: flex;
  display: flex;
  height: 100%; 
  max-height: 100%;
  flex: 1 1 auto;
}

#root {
  flex: 1 1 auto;
  display: flex;
}

.ads-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  min-width: 300px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header nav ul li a {
  text-decoration: none;
  color: #006064;
  font-size: 12px;
}

footer nav ul li a:visited {
  color: #006064;
}

footer nav ul li a:hover {
  text-decoration: underline;
}

html,
body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ads-horizontal {
  display: none;
}

#root h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2em;
  color: #006064;
}

#root>div {
  flex: 1 1 auto;
}

@media only screen and (max-width: 600px) {
  .ads-vertical {
    display: none;
  }

  .ads-horizontal {
    display: block;
  }

  body {
    flex-direction: column;
  }

  #root h1 {
    font-size: 18px;
    text-align: center;
    margin: 12px 0;
  }

  #root {
    width: calc(100vw - 32px);
  }
}