.cards {
    display: flex;
    margin: 2em auto;
    justify-content: center;
}

.cards .MuiPaper-root {
    box-shadow: 0 .125em .125em 0 #e5e5e5;
}

@media only screen and (max-width: 600px) {
    .cards {
        flex-wrap: wrap;
    }
}