.users {
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: calc(100vh - 53.72px - 144px - 32px);
}

.users .MuiPaper-root {
    box-shadow: 0 .125em .125em 0 #e5e5e5;
}

.users h2.summary-title {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 8px;
    color: #006064;
}