.user {
    display: flex;
    padding: 0.5em 2em;
    margin-bottom: 8px;
    color: #006064;
}

.user.viewer {
    background-color: rgba(0, 0, 0, 0.1);
}

.user-vote .vote, .summary-vote .card-value .vote {
    border: solid 1px;
    border-radius: 5px;
    height: 30px;
    width: 22px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-color: #006064;
    color: #006064;
    font-weight: 700;
    padding: 0;
    font-size: small;

}
.user-vote .vote .MuiSvgIcon-root, .summary-vote .card-value .vote .MuiSvgIcon-root {
    font-size: small;
}

.voted {
    background-color: #006064;
    border-color: #006064;
}

.unvoted {
    background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, .5) 5px, rgba(255, 255, 255, .5) 10px);
    background-color: #006064;
    border-color: #006064;
}

.user-name {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    color: #006064;
}

.user-name>.MuiCardContent-root,
.user-vote>.MuiCardContent-root {
    padding: 0 !important;
    font-size: 16px !important;
}

.user.viewer .user-vote {
    min-width: 32px;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user.viewer .user-vote .MuiCardContent-root {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user.viewer .user-vote .MuiCardContent-root svg {
    color: red;
}

.user-vote .voted svg {
    color: white;
}

@media only screen and (max-width: 600px) {
    .user .user-name .MuiTypography-root {
        font-size: 16px;
        line-height: unset;
    }

    .vote {
        height: 24px;
        width: 24px;
        font-size: 12px;
    }

    .user.viewer .user-vote {
        min-width: 24px;
        min-height: 32px;
    }
}