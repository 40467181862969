.summary {
  margin-left: 24px;
  min-width: 230px;
  display: flex;
  flex-direction: column;
}

.summary .MuiPaper-root {
  box-shadow: 0 .125em .125em 0 #e5e5e5;
}

.summary .MuiPaper-root .summary-content {
  padding: 2em 1.5em;
}

.summary .summary-vote {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
}

.summary .summary-vote:last-child {
  margin: 0;
}

.summary .summary-vote .card-value {
  z-index: 1;
  margin: 0 8px 0 0;
  display: flex;
  justify-content: center;
  color: #006064;
}

.summary .summary-vote .summary-bar {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.summary .summary-vote .MuiLinearProgress-root {
  flex: 1 1 auto;
  background-color: #e0f7fa;
}

.summary .summary-vote .MuiLinearProgress-root>span {
  background-color: #006064;
}

.summary h2.summary-title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 8px;
  color: #006064;
}

.summary .summary-vote .qty {
  color: #006064;
  font-weight: 600;
  font-size: small;
  min-height: 18.58px;
}

@media only screen and (max-width: 600px) {
  .summary .has-coffee img {
    display: none;
  }

  .summary .MuiPaper-root {
    min-width: 100%;
  }

  .summary .css-46bh2p-MuiCardContent-root {
    padding: 8px;
  }

  .summary {
    min-width: unset;
    margin-left: unset;
  }

  .summary .summary-vote .card-value {
    margin: 0;
    font-size: 12px;
    margin-right: 4px;
  }

  .summary .summary-vote .qty {
    font-size: 12px;
  }

  .card-value svg {
    height: 16px;
    width: 16px;
  }

  .summary h2.summary-title {
    margin-top: 8px;
  }
}