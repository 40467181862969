.card {
    height: 80px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 400;
    color: #006064 !important;
    margin-right: 16px;
}

.card:hover {
    background-color: #006064 !important;
    color: white !important;
}

.card:last-child {
    margin-right: 0;
}

.card-selected {
    background-color: #00838f !important;
    color: white !important;
}

.card .MuiCardContent-root:last-child {
    padding: 0;
}

.card.viewer {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: unset;
}

.card.viewer:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #006064 !important;
    cursor: default;
}

@media only screen and (max-width: 600px) {
    .card {
        height: 60px;
        width: 40px;
        margin: 0 4px;
        margin-bottom: 16px;
    }

    .card:last-child {
        margin-right: 4px;
    }

    .card .MuiCardContent-root {
        font-size: 16px;
    }
}