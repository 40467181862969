.app {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-width: 54.75em;
    width: 54.75em;
}

.room-info {
    flex: 1 1 auto;
    display: flex;
    margin-top: 2em;
}

.room-group {
    display: flex;
    flex: 1 1 auto;
}

@media only screen and (max-width: 600px) {
    .app {
        width: unset;
        margin: unset;
        max-width: unset;
    }

    .room-info {
        flex-direction: column;
        margin-top: 0;
    }

    .room-group {
        flex-direction: column;
    }
}