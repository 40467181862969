.about {
    padding: 32px 0;
    max-width: 54.75em;
    width: 54.75em;
}

.about p+p {
    margin: 15px 0;
}

.about p {
    line-height: 1.4;
    font-size: 16px;
    color: #006064;
}

.about h1, .about h2, .about h3 {
    font-weight: bold;
    color: #006064;
}

.about h1 {
    padding-bottom: 24px;
}

.about h2 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.about h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.about .MuiPaper-root .MuiCardContent-root {
    padding: 32px;
}